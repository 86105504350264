<template>
    <div class="con-wrap">
        <CarrotTitle title="전임교수(MI) 스케줄 예약">
        </CarrotTitle>
        <div class="board">
            <div class="mt-40">
                <div>
                    <div class="mb-20">
                        <span class="float-right">＊필수 입력 항목입니다.</span>
                        <div class="clear"></div>
                    </div>
                    <table class="table-row">
                        <colgroup>
                            <col width="180">
                            <col width="*">
                        </colgroup>
                        <tbody>
                            <tr>
                                <th><span class="txt-red">*</span> 강사명</th>
                                <td>
                                    <select class="w-400px" v-model="add.idx_tutor">
                                        <option value="">선택</option>
                                        <option :value="irow.idx" v-for="(irow, i) in comp.tutorList" :key="i">{{ irow.ename }}</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 기간</th>
                                <td>
                                    <label><input type="radio" v-model="add.daytype" @change="comp.changeDaytype" value="today"><span class="ml-5 mr-20"> 하루</span></label>
                                    <div class="po-relative dp-inblock w-150px h-32px">
                                        <div v-if="add.daytype=='period'" class="input-lock"></div>
                                        <CarrotDatePicker v-model.sync="add.tdate" @change="comp.changeDate" class="mr-40 inline-block"></CarrotDatePicker>
                                    </div>
                                    <label><input type="radio" v-model="add.daytype" @change="comp.changeDaytype" value="period"><span class="ml-5 mr-20"> 기간</span></label>
                                    <div class="po-relative dp-inblock w-300px h-32px">
                                        <div v-if="add.daytype=='today'" class="input-lock"></div>
                                        <CarrotDatePicker v-model.sync="add.sdate" class="inline-block"></CarrotDatePicker>
                                        ~ 
                                        <CarrotDatePicker v-model.sync="add.edate" class="inline-block"></CarrotDatePicker>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 요일</th>
                                <td>
                                    <div class="po-relative dp-inblock w-400px h-32px">
                                        <div v-if="add.daytype=='today'" class="input-lock"></div>
                                        <label v-for="d in comp.dayList" :key="d"><input type="checkbox" v-model="add.days" :value="d"><span class="ml-5 mr-20"> {{ d }}</span></label>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 시간</th>
                                <td>
                                    <CarrotTime24Select v-model="add.stime"></CarrotTime24Select>
                                     ~ 
                                    <CarrotTime24Select v-model="add.etime"></CarrotTime24Select>
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 스케줄</th>
                                <td>
                                    <input type="text" v-model.trim="add.title" class="w-100per" maxlength="50">
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <button @click="add.doCancel" class="btn-default float-left mt-30">취소</button>
                    <button @click="add.doSubmit" class="btn-default float-right mt-30">등록</button>
                    <div class="clear"></div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
// @ is an alias to /src 
import { onMounted, reactive } from 'vue'
import { useRouter } from 'vue-router'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'
import CarrotTime24Select from '@/components/common/CarrotTime24Select.vue'
import CarrotDatePicker from '@/components/common/CarrotDatePicker.vue'
import Swal from 'sweetalert2'


export default {
    layout:"ResourceManagement",
    components: {
        CarrotTime24Select,
        CarrotDatePicker
    },
    setup() {
        const router = useRouter();
        const toast = useToast()

        const add = reactive({
            idx_tutor  : "",
            daytype    : "today",
            tdate      : "",
            sdate      : "",
            edate      : "",
            stime      : "07:00",
            etime      : "07:00",
            days       : [],
            title      : "",

            doCancel: () => {
                router.go(-1);
            },

            doSubmit : () => {
                let params = {
                    idx_tutor    : add.idx_tutor,
                    sdate        : add.daytype=='today'?add.tdate:add.sdate,
                    edate        : add.daytype=='today'?add.tdate:add.edate,
                    sday         : add.days,
                    stime        : add.stime,
                    etime        : add.etime,
                    title        : add.title,
                };

                if( !params.idx_tutor ){
                    toast.error("강사를 선택하세요.");
                    return;
                }
                if( !params.sdate || !params.edate ){
                    toast.error("기간을 선택하세요.");
                    return;
                }
                if( params.sday.length <= 0 ){
                    toast.error("요일을 선택하세요.");
                    return;
                }
                if( !params.stime || !params.etime ){
                    toast.error("시간을 선택하세요.");
                    return;
                }
                if( params.stime >= params.etime ){
                    toast.error("시간을 확인해주세요.");
                    return;
                }
                if( !params.title ){
                    toast.error("스케줄을 입력하세요.");
                    return;
                }

                axios.post("/rest/resourceManagement/addSchedule", params).then((res) => {
                    if( res.data.err == 0 ){
                        Swal.fire({
                            title: '전임교수(MI) 스케줄 예약',
                            text : '저장하였습니다.'
                        }).then(() => {
                            router.push({
                                name : 'ResourceManagement-MIScheduleDate'
                            });
                        });
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },
        });

        const comp = reactive({
            tutorList: [],
            dayList: [ '월', '화', '수', '목', '금', '토', '일' ],

            getTutorList: () => {
                let params = {
                    page : 1,
                    rows : 1000
                };
                axios.get('/rest/resourceManagement/getTutorList', { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        comp.tutorList = res.data.list;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },

            changeDaytype : () => {
                if( add.daytype == 'today' ){
                    add.sdate = '';
                    add.edate = '';
                } else {
                    add.tdate = '';
                }
                add.days = [];
            },

            changeDate: () => {
                add.days = [];
                let td = new Date(add.tdate);
                let day = td.getDay();
                if( day == 0 ) add.days.push(comp.dayList[6])
                else add.days.push(comp.dayList[day-1])
            }
        });

        onMounted(() => {
            // Mounted
            comp.getTutorList();

            let ss = sessionStorage.getItem('MIScheduleAdd');
            if( ss ){
                ss = JSON.parse(ss);
                if( ss.today ){
                    add.tdate = ss.today;
                    comp.changeDate();
                }
                if( ss.time ){
                    add.stime = ss.time;
                    add.etime = ss.time;
                }
                if( ss.idx_tutor ){
                    add.idx_tutor = ss.idx_tutor;
                }

                console.log(ss);
            }
        });

        return {comp, add};
    }
}
</script>

<style lang="scss" scoped>
.input-lock {
    position:absolute; top:0; left:0; width:100%; height:100%; z-index:2; background-color:rgba(0,0,0,0.001);
}
</style>